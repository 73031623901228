import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./custom.css";
import "./main.css";
import SHT from "./Images/sagehillTechnologiesLogo.png";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "react-bootstrap/Toast";
import { useEffect } from "react";
import { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

function NavigationBar() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={SHT}
              className="d-flex"
              height={90}
              width={150}
              alt="LogoYe"
              style={{ width: "auto" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Link>
              <Navbar.Text onClick={() => setShow(true)}>
                <FontAwesomeIcon style={{ color: "#263972" }} icon={faUser} />
              </Navbar.Text>
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>{" "}
      <div style={{ position: "fixed", top: "70", right: "0", zIndex: "9999" }}>
        <Toast
          style={{
            display: "flex",
            justifyContent: "right",
            background: "transparent",
            borderColor:"none",
            border: "none",
            boxShadow:"none",
          }}
          onClose={() => setShow(false)}
          show={show}
          delay={4000}
          autohide
        >
          <Toast.Header style={{ background: "white", borderRadius: "10px" }}>
            <Link to="/" className="" style={{ textDecoration: "none" }}>
              {" "}
              <strong className="me-auto" style={{ color: "black" }}>
                Logout
              </strong>
            </Link>
          </Toast.Header>
        </Toast>
      </div>
    </div>
  );
}

export default NavigationBar;
