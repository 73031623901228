import React from "react";

function HoveringLogo() {
  return (
    <div>
      <div
        style={{
          display: "block",
          background: "white",
          position: "fixed",
          bottom: "190px",
          right: "0px",
          minWidth: "5%",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >Hi</div>
    </div>
  );
}

export default HoveringLogo;
