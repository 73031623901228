import React from "react";
import "./custom.css";
import "./main.css";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Navbar from "react-bootstrap/Navbar";
import synologyDevice from "./Images/synologyDevice.webp";
import kasperSky from "./Images/kasperSky.webp";
import palladium from "./Images/palladium.webp";
import hardware from "./Images/hardware.webp";
import Modal from "react-bootstrap/Modal";
import SHT from "./Images/sagehillTechnologiesLogo.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Nav from "react-bootstrap/Nav";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Landing from "./Landing";
import NavigationBar from "./NavigationBar";

export default function Blogs() {
  const [search, setSearch] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [articles, setArticles] = useState([]);
  const [category, setCategory] = useState([]);
  const [articlesTitle, setArticlesTitle] = useState("");
  const [categoryname, setCategoryname] = useState("");
  const [articlesBriefDescription, setArticlesBriefDescription] = useState("");
  const [searchedArticles, setSearchedArticles] = useState([]);
  // MODAL ON DELETE
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const baseurl = "https://knowledge-base-backend.onrender.com/";

  // GETTING ARTICLES
  useEffect(() => {
    const fetchAllArticles = async () => {
      try {
        const res = await axios.get(`${baseurl}articles`);
        setArticles(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllArticles();
  }, []);

  // GETTING CATEGORIES
  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const res = await axios.get(`${baseurl}category`);
        setCategory(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllCategories();
  }, []);

  // MODAL
  const openModal = (e) => {
    e.preventDefault();
    setLgShow(true);
  };

  useEffect(() => {}, []);

  // DELETE
  const handleDelete = async (articlesId) => {
    try {
      await axios.delete(`${baseurl}articles/` + articlesId);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  //CATEGORY FILTER

  //SEARCH TABLE ARTICLES
  // useEffect(()=>{
  //   const fetchSelected = async ()=>{
  //     try{
  //       const res = await axios.get("http://localhost:8800/articles/search", {articlesTitle})
  //       setSearchedArticles(res.data);

  //     }catch(err){
  //       console.log(err)
  //     }
  //   }
  //   fetchSelected()
  // },[])
  // FILTERING THE BLOGS
  const filterItems = (catItem) => {
    const updateItems = articles.filter((curItem) => {
      return curItem.articlesCategory === catItem;
    });
    setItems(updateItems);
  };

  const [items, setItems] = useState(articles);
  useEffect(() => {
    setItems(articles);
  }, [articles]);

  console.log(items);

  return (
    <div className="blogme">
      {/* LANDING */}
      <div>
        <div className="Home">
          <section id="home" className="">
          <NavigationBar></NavigationBar>
            <div className="image home-info d-flex flex-column justify-content-center align-items-center">
              <Container className="row">
                {/* <Row className='buttonRow'>
               <Button variant="secondary" className='logoutButton'>Log Out</Button>{' '}
             </Row> */}
                <Row>
                  <Col></Col>
                  <Col lg={{ span: 6, offset: 3 }}>
                    {/* <h1 className ="my-4"></h1> */}
                    <h2 className="black">How can we help?</h2>
                    <p className="black">
                      Browse through our frequently asked questions, tutorials,
                      and other self-help resources to find the answers you
                      need.
                    </p>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                        aria-label="Dollar amount (with dot and two decimal places)"
                      />
                      <a href="#blogs">
                        <Button className="search" variant="primary">
                          Search{" "}
                        </Button>{" "}
                      </a>
                    </InputGroup>
                    <div className="pt-3">
                      <Link to="/add">
                        <Button variant="outline-primary">Add Blog</Button>{" "}
                      </Link>
                      <Link to="/addUser">
                        <Button variant="outline-success">Add User</Button>{" "}
                      </Link>
                    </div>
                    {/* <button type="button"  className ="btn btn-outline-success mt-4 py-3 px-5 border-2 fw-bold rounded-pill">Contact Me</button> */}
                    <div className="row">
                      <div className="col-md-6"></div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
        </div>
      </div>
      {/* LANDING */}
      <div className="Blogs pad" id="blogs">
        <Container>
          <Row className="d-flex justify-content-center">
            <h2 className="black d-flex justify-content-center pb-3 " lg={12}>
              Blog Category
            </h2>
            {/* MAPPING CATEGORIES */}
            <Nav
              variant="tabs"
              defaultActiveKey="/home"
              className="justify-content-center pb-3 navItems"
            >
              <Nav.Item>
                <Link to="/addCategory">
                  <Button variant="outline-primary">Add Category</Button>{" "}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  disabled={false}
                  className="zeropad categoryButton noline"
                >
                  <Button
                    variant="outline-primary"
                    onClick={() => setItems(articles)}
                    className="categoryButton"
                  >
                    All
                  </Button>{" "}
                </Nav.Link>
              </Nav.Item>
              {category.map((category) => (
                <Nav.Item className="noline" key={category.categoryid}>
                  <Nav.Link
                    eventKey="link-1"
                    className="categoryButton noline zeropad"
                  >
                    <Button
                      variant="outline-primary"
                      onClick={() => filterItems(category.categoryname)}
                      className="categoryButton"
                    >
                      {category.categoryname}
                    </Button>{" "}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Row>
          <Row className="d-flex justify-content-center">
            {/* MAPPING ARTICLES */}
            {items
              .filter((articles) => {
                const lowercaseSearch = search.toLowerCase();
                const lowercaseTitle = articles.articlesTitle.toLowerCase();

                return lowercaseSearch === ""
                  ? articles
                  : lowercaseTitle.includes(lowercaseSearch);
              })
              .map((articles) => (
                <Col
                  key={articles.articlesId}
                  xs={12}
                  md={6}
                  lg={4}
                  className="pad d-flex justify-content-center"
                >
                  {/* MODAL VIEW*/}
                  <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        {articles.articlesTitle}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{articles.articlesBody}</Modal.Body>
                  </Modal>
                  <Card style={{ width: "22rem", padding: "10px" }}>
                    <a href="" target="_self">
                      <Link to={`/article/${articles.articlesId}`}>
                        {articles.articlesImageUrl && (
                          <Card.Img
                            variant="top"
                            src={articles.articlesImageUrl}
                          />
                        )}
                      </Link>
                    </a>
                    <Card.Body>
                      <a href="" target="_self" className="no-underline">
                        <Link
                          to={`/article/${articles.articlesId}`}
                          className="no-underline"
                        >
                          <Card.Title style={{ color: "black" }}>
                            {articles.articlesTitle}
                          </Card.Title>
                        </Link>
                      </a>
                      <Card.Text>
                        {articles.articlesBriefDescription + "..."}
                      </Card.Text>
                      <Card.Text>
                        <a href="" target="_self">
                          <Link to={`/article/${articles.articlesId}`}>
                            <FontAwesomeIcon icon={faAnglesRight} />
                          </Link>
                        </a>
                        <Button
                          className="ml-7"
                          onClick={handleShow}
                          style={{ marginLeft: "7px" }}
                          variant="outline-danger"
                        >
                          Delete
                        </Button>{" "}
                        <Link to={`/update/${articles.articlesId}`}>
                          <Button variant="outline-secondary">Update</Button>{" "}
                        </Link>
                      </Card.Text>

                      {/* MODAL ON DELETE */}
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to delete?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button
                            onClick={() => handleDelete(articles.articlesId)}
                            variant="danger"
                          >
                            Delete
                          </Button>{" "}
                        </Modal.Footer>
                      </Modal>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
